<template>
  <div>
    <div class="room-title-top text-uppercase">
      <h3>{{ title }}</h3>
    </div>
    <div class="room-title-center text-uppercase">
      <h3>{{ title }}</h3>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/mixins/cookie-mixin'
import { gsap } from 'gsap'

export default {
  name: 'RoomTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      tls: [],
      titleSeen: false
    }
  },
  beforeDestroy () {
    this.tls && this.tls.length && this.tls.forEach((tl, i) => { this.tls[i].kill() })
  },
  mounted () {
    this.$events.on('tutorial-hide', () => {
      if (!this.titleSeen) {
        this.tls && this.tls.length && this.tls[0].play()
      }
    })
    this.$events.$emit('toggle-hotspots', false)
    this.createTimelines()

    if (!this.$route.params.hotspot) {
      if (this.getCookie('tutorial-seen-cookie')) {
        this.tls[0].play()
      }
    } else {
      this.onReverseComplete()
    }
  },
  methods: {
    getCookie,
    createTimelines () {
      this.tls[0] = gsap.timeline({ paused: true, delay: 1, onComplete: this.onComplete, onReverseComplete: this.onReverseComplete })
      this.tls[0].fromTo('.room-title-center', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' })
      this.tls[0].fromTo('.room-title-center h3', { autoAlpha: 0, translateY: 15 }, { autoAlpha: 1, translateY: 0, duration: 0.5, ease: 'sine.easeInOut' }, '-=0.05')

      this.tls[1] = gsap.timeline({ paused: true, onComplete: this.onSecondTLComplete })
      this.tls[1].fromTo('.room-title-top', { autoAlpha: 1 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' })
      this.tls[1].fromTo('.room-title-top h3', { autoAlpha: 0, translateY: -15 }, { autoAlpha: 1, translateY: 0, duration: 0.5, ease: 'sine.easeInOut' }, '-=0.05')
    },
    onComplete () {
      setTimeout(() => { this.tls[0] && this.tls[0].reverse() }, 1250)
    },
    onReverseComplete () {
      this.tls[1] && this.tls[1].play()
    },
    onSecondTLComplete () {
      this.$events.$emit('toggle-hotspots', true)
      this.titleSeen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.room-title-top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: rem(110px);
  text-align: center;
  width: 90%;
  letter-spacing: 2px;

  @include breakpoint('md-and-up') {
    bottom: auto;
    width: 60%;
    top: rem($ui-distance-desktop + 5px);
  }
}

.room-title-center {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-room-title;
  background-color: rgba(186, 161, 141, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 5vw;
    text-shadow: 1px 1px 4px rgba(55, 55, 55, 0.4);
    letter-spacing: 4px;
    text-align: center;

    @include breakpoint ('sm-and-down') {
      font-size: 6.5vw;
    }
  }
}
</style>
