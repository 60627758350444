<template>
  <div class="panolens">
    <div @click="onPanoClick" @touchstart="onPanoClick" ref="pano" class="pano-container"></div>
    <v-fade-transition>
      <div v-if="panoVisible" class="gradient gradient-top"></div>
    </v-fade-transition>
    <v-fade-transition>
      <div v-if="panoVisible" class="gradient gradient-bottom"></div>
    </v-fade-transition>
    <div ref="panohotspot" class="pano-hotspot-container"></div>
    <div v-if="room" class="hotspot-reference">
      <template v-if="room.hotspots">
        <div
          v-for="(hotspot, index) in room.hotspots"
          :key="`hs${index}`"
          :ref="`hotspot-info${index}`"
        >
          <HotspotHtml :show="hotspotsVisibles" :hotspot="hotspot.reference" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Viewer, InfospotSprite } from 'panolens/build/panolens.module'
import { Viewport } from '@monogrid/vue-lib'
import { Vector3 } from 'three'
import HotspotHtml from '@/atoms/HotspotHtml'
import PanoCache from '@/utils/pano-cache'
import infoCircle from '@/assets/img/info-circle.png'

export default {
  name: 'Panolens360',
  mixins: [Viewport],
  components: { HotspotHtml },
  props: {
    room: { type: Object }
  },
  data () {
    return {
      infoCircle,
      panoVisible: false,
      currentPanorama: null,
      hotspotsVisibles: false,
      video360Playing: false,
      video360Seen: false
    }
  },
  mounted () {
    // Use this code to get the corret value to block the camera
    document.addEventListener('keyup', (e) => {
      if (e.code === 'KeyA' && this.viewer.OrbitControls) {
        console.log('Azimuth angle:', this.viewer.OrbitControls.getAzimuthalAngle())
      }
    })

    this.$events.on('toggle-hotspots', this.toggleHotspots)

    this.fixedCamera = true
    this.initPano()
  },
  watch: {
    room (room, oldRoom) {
      if (room && oldRoom && room.id === oldRoom.id) return
      this.onChangeRoom(room)
    },
    viewPort () {
      this.checkCameraLimit()
    }
  },
  methods: {
    initPano () {
      const target = new Vector3(-100, -1000, 0)
      const { x, y, z } = target.normalize()

      this.infospots = []
      this.viewer = new Viewer({
        output: 'console',
        container: this.$refs.pano,
        controlBar: false,
        autoHideInfospot: false,
        autoRotate: false
      })

      this.viewer.control.noKeys = true
      this.viewer.camera.position.set(x, -y, -z)
      this.viewer.setCameraFov(this.viewPort.width < 600 ? 90 : 70)
      this.viewer.OrbitControls.noZoom = true
      this.viewer.OrbitControls.minPolarAngle = Math.PI / 3

      this.setPano()
    },
    setInitalPoint () {
      this.panoVisible = true

      if (this.room.originRoomPoint) {
        for (let i = 0; i < this.room.originRoomPoint.length; i++) {
          const element = this.room.originRoomPoint[i]

          if (element.originRoom._slug === this.$root.previousRoomSlug) {
            const ip = element.position.split(',')

            // Avoid wrong camera initial point
            setTimeout(() => {
              this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 0)
            }, 10)

            this.$root.previousRoomSlug = this.room._slug
            return
          }
        }
      }

      this.$root.previousRoomSlug = this.room._slug

      if (!this.room.initialPoint) return
      const ip = this.room.initialPoint.split(',')

      // Avoid wrong camera initial point
      setTimeout(() => {
        this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 0)
      }, 10)
    },
    onPanoClick () {
      if (!this.video360Playing) {
        // Force autoplay ...
        if (this.currentPanorama.videoElement === undefined || !this.currentPanorama.videoElement) return
        this.currentPanorama.videoElement.currentTime = 0
        this.currentPanorama.videoElement.play()
      }
    },
    onPanoramaLeave (e) {
      this.viewer.remove(e.target)
    },
    async setPano () {
      await this.$nextTick()

      if (!this.room) return

      const showVideo = this.room.video360 && !this.video360Seen
      let roomVideo = this.room.video360
      let roomImage = this.room.image360

      if (this.$root.platform.mobile && this.room.video360Mobile) {
        roomVideo = this.room.video360Mobile
      }

      if (this.$root.platform.mobile && this.room.image360Mobile) {
        roomImage = this.room.image360Mobile
      }

      const { panorama } = showVideo
        ? PanoCache.get(roomVideo)
        : PanoCache.get(roomImage)

      if (showVideo) { // preload image 360 ..
        PanoCache.get(this.room.image360)
        panorama.videoElement.removeEventListener('loadeddata', this.onVideo360Loaded)
        panorama.videoElement.addEventListener('loadeddata', this.onVideo360Loaded)
        panorama.videoElement.removeEventListener('playing', this.onVideo360Playing)
        panorama.videoElement.addEventListener('playing', this.onVideo360Playing)
        panorama.videoElement.removeEventListener('timeupdate', this.onVideo360TimeUpdate)
        panorama.videoElement.addEventListener('timeupdate', this.onVideo360TimeUpdate)

        panorama.videoProgress = 0
        this.video360Seen = false
        this.video360Playing = false
      } else {
        panorama.reset()
      }

      if (!this.video360Seen) {
        panorama.removeEventListener('enter-fade-start', this.setInitalPoint)
        panorama.addEventListener('enter-fade-start', this.setInitalPoint)
      }

      this.viewer.OrbitControls.maxPolarAngle = this.room.limitCameraTop
        ? Math.PI * 0.58
        : Math.PI * 0.9

      panorama.removeEventListener('leave-complete', this.onPanoramaLeave)
      panorama.addEventListener('leave-complete', this.onPanoramaLeave)

      this.checkCameraLimit()

      const dummyHotspot1 = new InfospotSprite(0, this.infoCircle)
      const dummyHotspot2 = new InfospotSprite(0, this.infoCircle)
      panorama.add(dummyHotspot1)
      panorama.add(dummyHotspot2)

      this.createHotspot()
      this.infospots.forEach(infospot => panorama.add(infospot))
      this.viewer.control.enabled = true
      this.viewer.add(panorama)
      this.viewer.setPanorama(panorama)
      this.currentPanorama = panorama
    },
    createHotspot () {
      if (!this.room.hotspots) return

      for (let i = 0; i < this.room.hotspots.length; i++) {
        const hs = this.room.hotspots[i]
        if (hs.position) {
          if (this.$refs['hotspot-info' + i] && this.$refs['hotspot-info' + i][0]) {
            const panel = this.$refs['hotspot-info' + i][0]
            const infospot = new InfospotSprite(1, this.infoCircle)
            const pos = hs.position.split(',')

            infospot.position.set(pos[0], pos[1], pos[2])
            infospot.addHoverElement(panel, 150, false, this.$refs.panohotspot)
            if (hs.reference && hs.reference.roomTo) {
              // preload room
              PanoCache.get(hs.reference.roomTo.image360)
            }
            this.infospots.push(infospot)
          }
        }
      }
    },
    toggleHotspots (value) {
      if (!this.video360Playing) {
        this.hotspotsVisibles = value
      }
    },
    cleanPano () {
      if (this.currentPanorama) {
        this.currentPanorama.reset()
      }
      for (let i = 0; i < this.infospots.length; i++) {
        this.infospots[i].removeHoverElement()
        this.infospots[i].dispose()
      }
      this.infospots = []

      if (this.$refs.panohotspot) {
        this.$refs.panohotspot.innerHTML = ''
      }
    },
    async onChangeRoom (room) {
      this.video360Playing = false
      this.video360Seen = false
      this.viewer.control.enabled = false
      this.cleanPano()
      await this.$nextTick()
      this.setPano()
    },
    checkCameraLimit () {
      if (this.room && (this.room.limitCameraValues || this.room.limitCameraMobileValues)) {
        let MIN = 0
        let MAX = 0

        if (this.$root.platform.mobile) {
          if (this.viewPort.width > 450) {
            MIN = parseFloat(this.room.limitCameraValues[0])
            MAX = parseFloat(this.room.limitCameraValues[1])
          } else {
            MIN = parseFloat(this.room.limitCameraMobileValues[0])
            MAX = parseFloat(this.room.limitCameraMobileValues[1])
          }
        } else {
          MIN = parseFloat(this.room.limitCameraValues[0])
          MAX = parseFloat(this.room.limitCameraValues[1])
        }

        if (MIN < MAX) {
          this.viewer.OrbitControls.minAzimuthAngle = MIN
          this.viewer.OrbitControls.maxAzimuthAngle = MAX
        } else {
          this.viewer.OrbitControls.minAzimuthAngle = MAX
          this.viewer.OrbitControls.maxAzimuthAngle = MIN
        }
      } else {
        this.viewer.OrbitControls.minAzimuthAngle = -Infinity
        this.viewer.OrbitControls.maxAzimuthAngle = Infinity
      }
    },
    onVideo360Loaded () {
      // Force autoplay ...
      setTimeout(() => { this.$refs.pano.click() }, 250)
    },
    onVideo360Playing () {
      this.video360Playing = true
    },
    onVideo360TimeUpdate () {
      if (this.currentPanorama.videoElement === undefined || !this.currentPanorama.videoElement) return
      if (this.currentPanorama.videoElement.currentTime > (this.currentPanorama.videoElement.duration - 2)) {
        this.currentPanorama.videoElement.pause()
        this.onVideo360Ended()
      }
    },
    onVideo360Ended () {
      this.video360Playing = false
      this.hotspotsVisibles = true
      this.video360Seen = true
      this.setPano()
    }
  }
}
</script>

<style lang="scss" scoped>
.panolens {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transition: opacity 0.4s 0.3s;
}

.pano-container {
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.pano-hotspot-container {
  position: absolute;
  top: 0;
  left: 0;
}

.hotspot-reference {
  position: absolute;
  display: none;
}

.gradient {
  position: absolute;
  left: 0;
  right: 0;
  height: rem(200px);
  pointer-events: none;
  z-index: 1;
}

.gradient-top {
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.gradient-bottom {
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
</style>
