<template>
  <MarkdownBlock :text="text" :inline="false" />
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'HotspotText',
  components: { MarkdownBlock },
  props: {
    text: { type: String }
  }
}
</script>
