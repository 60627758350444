<template>
  <div :class="{ small: hotspot.contentSmallPopup }" class="hotspot">
    <v-container class="hotspot-container">
      <ButtonClose :absolute="true" @click.stop="onClose" />
      <v-row no-gutters>
        <v-col :cols="12">
          <HotspotTitle class="hotspot-main-title stagger" :text="hotspot.shareTitle" />
        </v-col>
        <v-col
          v-for="(content, index) in hotspot.content"
          :key="index"
          :cols="12"
          class="hotspot-content"
        >
          <component
            v-bind="content"
            :is="'Hotspot' + content._repeaterType"
            :class="`hotspot-${content._repeaterType}`"
            class="stagger"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import ButtonClose from '@/atoms/ButtonClose'
import HotspotTitle from '@/atoms/HotspotTitle'
import HotspotText from '@/atoms/HotspotText'

export default {
  components: { ButtonClose, HotspotTitle, HotspotText },
  name: 'HotspotContent',
  props: {
    hotspot: { type: Object }
  },
  mounted () {
    this.$events.$emit('hotspot-open')
    this.createTimeline()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    onClose () {
      this.tl.tweenTo('hide', { onComplete: () => { this.$events.$emit('hotspot-close') } })
    },
    createTimeline () {
      this.tl = gsap.timeline()
      this.tl.addLabel('hide')
      this.tl.fromTo('.hotspot', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' })
      this.tl.fromTo('.hotspot .hotspot-container', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' })
      this.tl.fromTo('.stagger', { autoAlpha: 0, translateY: -15 }, { autoAlpha: 1, translateY: 0, duration: 0.5, stagger: 0.2, ease: 'sine.easeInOut' }, '-=0.1')
      this.tl.addLabel('show')
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-hotspot;
  background: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100%;
}

.hotspot-container {
  position: relative;
  background: $bg-gradient;
  margin: 5% auto;
  padding: rem(120px 100px);
  max-width: 1400px;

  @include breakpoint ('lg-only') {
    max-width: 1200px;
  }

  @include breakpoint ('md-only') {
    max-width: 860px;
  }

  @include breakpoint ('sm-only') {
    max-width: 640px;
    padding: rem($ui-distance-mobile);
  }

  @include breakpoint ('xs-only') {
    margin: 0;
    max-width: 100%;
    min-height: 100%;
    padding: rem($ui-distance-mobile);
  }
}

.hotspot-main-title,
.hotspot-Title {
  font-weight: 600;
  font-size: rem(80px);
  line-height: rem(72px);
  letter-spacing: rem(2px);
  text-transform: uppercase;
  padding-bottom: rem(55px);

  @include breakpoint ('xs-only') {
    font-size: rem(26px);
    padding-top: rem(30px);
    padding-bottom: 0;
  }
}

.hotspot-Title {
  padding-top: rem(55px);
}

.hotspot-Text {
  font-size: rem(16px);
  line-height: rem(24px);
  letter-spacing: rem(2px);
  padding: rem(20px 0);
}

.hotspot.small {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include breakpoint ('xs-only') {
    max-width: auto;
    min-height: auto;
  }

  .hotspot-container {
    max-width: rem(650px);
    padding: rem(70px);

    @include breakpoint ('xs-only') {
      margin: $ui-distance-mobile;
      max-width: auto;
      min-height: auto;
      padding: rem($ui-distance-mobile);
    }
  }

  .hotspot-main-title,
  .hotspot-Title {
    font-size: rem(30px);
    line-height: rem(24px);
    padding-bottom: rem(20px);

    @include breakpoint ('xs-only') {
      font-size: rem(26px);
      padding-top: rem(30px);
    }
  }

  .hotspot-Text {
    padding: rem(15px 0);
  }
}
</style>
