<template>
  <transition name="fade-transition" mode="out-in" appear>
    <router-link
      v-if="!!routeLink && show"
      @click.native="onClick()"
      :to="routeLink"
      :style="{
        transform: (hotspot.type === 'room' && hotspot.iconRotation)
          ? 'rotate(' + hotspot.iconRotation + 'deg)'
          : null
      }"
      class="hotspot-wrapper"
    >
      <span class="inner"></span>
      <component :is="icon" />
    </router-link>
  </transition>
</template>

<script>
import { pushGtmEvent } from '@/utils/analytics-events'
import { find, findIndex } from 'lodash'
import ArrowSvg from '@/assets/svg/arrow.svg'

export default {
  name: 'HotspotHtml',
  components: { ArrowSvg },
  props: {
    hotspot: { type: Object },
    show: { type: Boolean }
  },
  computed: {
    routeLink () {
      if (this.hotspot.type === 'content') {
        return {
          params: { hotspot: this.hotspot._slug }
        }
      }

      // Assuming the hotspot type is "room" or "exit"
      if (!this.hotspot.roomTo) return false

      // To work offline
      if (!this.$root.Floors) return false
      const floor = find(this.$root.Floors.items, floor => findIndex(floor.rooms, room => room.reference.id === this.hotspot.roomTo.id) !== -1)._slug

      return {
        params: { floor, room: this.hotspot.roomTo._slug }
      }
    },
    icon () {
      let icon = null
      switch (this.hotspot.type) {
        case 'content':
          icon = 'plus'
          break
        case 'room':
          icon = 'arrow'
          break
        case 'exit':
          icon = 'exit'
          break
        case 'action':
          icon = 'action'
          break
        default:
          icon = 'arrow'
          break
      }
      return require('@/assets/svg/' + icon + '.svg')
    }
  },
  methods: {
    pushGtmEvent,
    onClick () {
      if (!this.$root.Floors) return false
      const floor = find(this.$root.Floors.items, floor => floor._slug === this.$route.params.floor)
      const room = find(floor.rooms, room => room.reference._slug === this.$route.params.room)
      pushGtmEvent('Click on Hotspots', room.reference.title, this.hotspot.shareTitle)
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-transition-active,
.fade-transition-leave {
  transition-delay: 0s;
  transition-duration: 0.05s;
}

.hotspot-wrapper {
  display: block;
  width: rem(58px);
  height: rem(58px);
  border: 0.65px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  position: relative;

  &:hover {
    .inner {
      width: 100%;
      height: 100%;
    }
  }

  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;
    background-color: $c-primary;
    transition: all 0.2s ease-in-out;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    stroke: $c-white;
    stroke-width: 1px;
  }
}
</style>
