<template>
  <div class="cookie-links">
    <a
      v-if="$root.Setting.cookiePolicy"
      :href="$root.Setting.cookiePolicy.url"
      @click="onClick($root.Setting.cookiePolicy.label)"
      target="_blank"
      rel="noopener"
    >
      <small>{{ $root.Setting.cookiePolicy.label }}</small>
    </a>
    <a
      v-if="$root.Setting.cookiePrivacy"
      :href="$root.Setting.cookiePrivacy.url"
      @click="onClick($root.Setting.cookiePrivacy.label)"
      target="_blank"
      rel="noopener"
    >
      <small>{{ $root.Setting.cookiePrivacy.label }}</small>
    </a>
  </div>
</template>

<script>
import { pushGtmEvent } from '@/utils/analytics-events'

export default {
  name: 'CookieLinks',
  methods: {
    pushGtmEvent,
    onClick (label) {
      pushGtmEvent('Footer', label, '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-links {
  position: fixed;
  height: 30px;
  bottom: rem($ui-distance-mobile);
  left: rem($ui-distance-mobile);
  right: rem($ui-distance-mobile);
  display: flex;
  justify-content: space-between;
  z-index: $z-room-ui;

  @include breakpoint ('md-and-up') {
    width: rem(250px);
    right: auto;
    bottom: rem($ui-distance-desktop);
    left: rem($ui-distance-desktop);
  }
}

a {
  text-decoration: none;
  color: $c-white;
  letter-spacing: 1px;

  @include breakpoint ('md-and-up') {
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
