<template>
  <div class="tutorial">
    <v-container class="tutorial-container">
      <v-row no-gutters>
        <v-col :cols="12" align="center">
          <h1 class="tutorial-title">{{ tutorialTitle }}</h1>
        </v-col>
        <v-col
          v-for="(item, index) in tutorialContent"
          :key="index"
          :cols="12"
          :sm="12 / tutorialContent.length"
          :class="(`item-${index}`)"
          align="center"
          class="tutorial-item"
        >
          <component :is="item.icon + 'Svg'" />
          <MarkdownBlock :text="item.text" :inline="true" class="tutorial-text" />
        </v-col>
        <v-col :cols="12" align="center">
          <button
            @click="closeTutorial()"
            type="button"
            aria-label="Close tutorial"
            class="tutorial-continue-btn"
          >
            {{ tutorialContinueLabel }}
          </button>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getCookie, setCookie, eraseCookie } from '@/mixins/cookie-mixin'
import { gsap } from 'gsap'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ArrowSvg from '@/assets/svg/tutorial-arrow.svg'
import DragSvg from '@/assets/svg/tutorial-drag.svg'
import PlusSvg from '@/assets/svg/tutorial-plus.svg'

export default {
  name: 'Tutorial',
  components: { MarkdownBlock, ArrowSvg, DragSvg, PlusSvg },
  props: {
    tutorialTitle: { type: String },
    tutorialContent: { type: [Array, Object] },
    tutorialContinueLabel: { type: String }
  },
  data () {
    return {
      tutorialSeen: false
    }
  },
  mounted () {
    this.$events.on('tutorial-show', this.showTutorial)
    this.createTimeline()
    if (this.getCookie('tutorial-seen-cookie')) {
      this.tutorialSeen = this.getCookie('tutorial-seen-cookie')
    }
    if (!this.tutorialSeen) {
      this.tl && this.tl.play()
    }
  },
  methods: {
    getCookie,
    setCookie,
    eraseCookie,
    createTimeline () {
      this.tl = gsap.timeline({ paused: true, onReverseComplete: this.onReverseComplete })
      this.tl.fromTo('.tutorial', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' })
      this.tl.fromTo('.tutorial .tutorial-title', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' }, '+=0.25')
      this.tl.fromTo('.tutorial .tutorial-item', { autoAlpha: 0, translateY: -15 }, { autoAlpha: 1, translateY: 0, duration: 0.5, stagger: 0.18, ease: 'sine.easeInOut' })
      this.tl.fromTo('.tutorial .tutorial-continue-btn', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'sine.easeInOut' }, '+=0.6')
    },
    onReverseComplete () {
      // Save the tutorial state in the cookie document for 7 days ...
      if (this.getCookie('tutorial-seen-cookie')) {
        this.eraseCookie('tutorial-seen-cookie')
      }
      this.setCookie('tutorial-seen-cookie', true, 7)
      this.$events.$emit('tutorial-hide')
    },
    showTutorial () {
      if (this.getCookie('tutorial-seen-cookie')) {
        this.eraseCookie('tutorial-seen-cookie')
      }
      this.tl && this.tl.play()
    },
    closeTutorial () {
      this.tl && this.tl.reverse()
    }
  }
}
</script>

<style lang="scss" scoped>
.tutorial {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-tutorial;
  background: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100%;

  .tutorial-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .tutorial-title {
    font-weight: bold;
    font-size: rem(30px);
    line-height: rem(24px);
    text-transform: uppercase;
    margin-bottom: rem(85px);
    letter-spacing: rem(2.5px);

    @include breakpoint ('xs-only') {
      margin-bottom: rem(50px);
    }
  }

  .tutorial-item {
    &.item-1 {
      @include breakpoint ('xs-only') {
        padding: rem(20px 0);
      }
    }
  }

  .tutorial-text {
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: rem(2px);
  }

  .tutorial-continue-btn {
    position: relative;
    margin-top: rem(75px);
    letter-spacing: rem(2.5px);
    text-transform: uppercase;

    @include breakpoint ('xs-only') {
      margin-top: rem(40px);
    }

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 40%;
      background: $c-white;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      transform-origin: left center;
      transition: width 0.2s ease-out;
    }

    &:hover {
      &::after {
        width: 90%;
      }
    }
  }
}
</style>
