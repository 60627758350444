<template>
  <div>
    <Tutorial v-if="!!Tutorial" v-bind="Tutorial" />
    <Panolens360 :room="Room" />
    <RoomTitle
      v-if="!!Room"
      :key="Room.shareTitle || Room.title"
      :title="(Room.shareTitle || Room.title)"
    />
    <Social />
    <CookieLinks />
    <HotspotContent
      v-if="!!Hotspot && isHotspotOpen"
      :hotspot="Hotspot"
    />
  </div>
</template>

<script>
import ShareMixin from '@/mixins/share-mixin'
import RoomQuery from '@/graphql/GetRoom.gql'
import HotspotQuery from '@/graphql/GetHotspot.gql'
import TutorialQuery from '@/graphql/GetTutorial.gql'
import Panolens360 from '@/organisms/Panolens360'
import HotspotContent from '@/molecules/HotspotContent'
import Social from '@/atoms/Social'
import CookieLinks from '@/atoms/CookieLinks'
import RoomTitle from '@/atoms/RoomTitle'
import Tutorial from '@/atoms/Tutorial'

export default {
  name: 'Room',
  props: {
    floor: { type: String },
    room: { type: String },
    hotspot: { type: String }
  },
  mixins: [ShareMixin],
  components: { Panolens360, HotspotContent, Social, CookieLinks, RoomTitle, Tutorial },
  apollo: {
    Room: {
      query: RoomQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          slug: this.room
        }
      },
      async result () {
        if (!this.Room) {
          this.$router.push({ name: 'Home' })
        }
      }
    },
    Hotspot: {
      query: HotspotQuery,
      skip () {
        return !this.hotspot
      },
      variables () {
        return {
          status: 'PUBLISHED',
          slug: this.hotspot
        }
      },
      async result () {
        if (!this.Hotspot) {
          this.$router.replace({
            params: {
              floor: this.floor,
              room: this.room,
              hotspot: null
            }
          })
        } else {
          this.isHotspotOpen = true
        }
      }
    },
    Tutorial: {
      query: TutorialQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          id: 'fbbf8c8d-3421-4c8d-8197-796cce822ec5'
        }
      }
    }
  },
  data () {
    return {
      isHotspotOpen: false
    }
  },
  mounted () {
    this.$events.on('hotspot-close', this.onHotspotClose)
  },
  methods: {
    onHotspotClose () {
      this.$router.replace({ params: { hotspot: null } })
      this.isHotspotOpen = false
    }
  },
  metaInfo () {
    if (this.Room) {
      return this.getShare({
        title: this.Room.shareTitle,
        description: this.Room.shareDescription || this.$root.Setting.shareDescription,
        image: this.Room.shareImage || this.Room.imagePreview
      })
    }
  }
}
</script>

<style scoped lang="scss">
.room-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: rem(110px);
  text-align: center;
  width: 90%;
  letter-spacing: 2px;

  @include breakpoint('md-and-up') {
    bottom: auto;
    width: 60%;
    top: rem($ui-distance-desktop + 5px);
  }
}

</style>
