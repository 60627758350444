<template>
  <div class="social">
    <a
      @click="onClick('Instagram')"
      href="https://www.instagram.com/gucci/"
      target="_blank"
      class="icon-social"
    >
      <InstagramIcon />
    </a>
    <a
      @click="onClick('Facebook')"
      href="https://www.facebook.com/GUCCI/"
      target="_blank"
      class="icon-social"
    >
      <FacebookIcon />
    </a>
  </div>
</template>

<script>
import { pushGtmEvent } from '@/utils/analytics-events'
import FacebookIcon from '@/assets/svg/facebook.svg'
import InstagramIcon from '@/assets/svg/instagram.svg'

export default {
  name: 'Social',
  components: {
    FacebookIcon,
    InstagramIcon
  },
  methods: {
    pushGtmEvent,
    onClick (social) {
      pushGtmEvent('Footer', social, '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.social {
  position: fixed;
  height: 30px;
  bottom: rem(40px);
  right: rem($ui-distance-mobile);
  width: rem(50px);
  display: flex;
  justify-content: space-between;
  z-index: $z-room-ui;
  @include breakpoint ('md-and-up') {
    width: rem(80px);
    bottom: rem($ui-distance-desktop);
    right: rem($ui-distance-desktop);
  }
}

.icon-social {
  display: inline-block;
  width: rem(18px);
  height: rem(18px);

  @include breakpoint ('md-and-up') {
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: $c-white;
  }
}
</style>
